<template>
    <div class="ProductRegistration">
			<!--我的个人资料区域-->
			<div class="user-wrap card">
        <el-row>
          <el-col :span="11">
            <div>
                Product ID
            </div>
          </el-col>
          <el-col :span="12">
            <div>
              Product ID?
              <el-tooltip effect="light"  placement="top-start">
                <div slot="content">
                  <el-row>
                      <el-col :span="8">
                        <img src="@/assets/img/bar_code.webp" style="width: 100%;" alt="Bar Code">
                      </el-col>
                      <el-col :span="16">
                        <p style="margin: 10px;font-weight: bold;line-height: 14px;font-size: 0.72vw;">Where can I find the Product ID?</p>
                        <p style="margin-left: 3px;line-height: 14px;font-size: 0.63vw;">You can find the 11-digit Product ID below</p>
                        <p style="margin-left: 3px;line-height: 14px;font-size: 0.63vw;">the barcode on the product packaging.</p>
                      </el-col>
                    </el-row>
                </div>
                <a href="javascript:;" class="red">Where can I find the Product ID?</a>
              </el-tooltip>
           </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="19">
            <el-input v-model="productRegister.productNo" ></el-input>
          </el-col>
          <el-col :span="5">
            <el-button @click="verifyNo">Verify</el-button>
          </el-col>
        </el-row>
        <div class="error_msg" v-if="isErrorMsg">The provided Product ID is invalid. Kindly re-enter the correct details. Should the issue persist, please reach out to our customer support at contact@neuronmocap.com for further assistance with your registration.</div>
				
     </div>
            
    <!--产品注册区域-->
    <el-table :data="productRegisterList" class="ProductRegistration-table" style="width: 100%; margin-bottom: 24px;">
      <el-table-column label="Product Type"  prop="productTypeName" width="320"  > </el-table-column>
      <el-table-column label="Product ID" prop="productNo"   align="center"> </el-table-column>     
      <el-table-column label="Activation Code"  prop="activationCode"  align="center" width="300"> </el-table-column>     
      <el-table-column label="Warranty Expiration Date" prop="serviceEndDate" align="center"  width="220"></el-table-column>     
    </el-table>  

    <el-dialog  :visible.sync="successDialog" class="success-dialog">
          <div>
            <div class="success-title">Thank you for your support of Noitom products.</div>
            <div class="success-content">
                    Your product registration and software activation process have been successfully completed.<br/>
                    Please click to download the software package tailored for your registered product.
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="downloadAccountSoftward">Download</el-button>
          </span>
      </el-dialog>

    </div>  
</template>

<script>
import {
  listProductRegisterHttp,
  addProductRegisterHttp,
  getProductByNoHttp
} from "@/api/getData";
export default {
  name: 'ProductRegistrationIndex',
  data() {
    return {
      productRegisterList:[],
      productRegister:{
        memberId:this.gMemberId,
        productNo:'',
        productId:'', 
        productTypeId:'',
        accountUrl:"",
        userType:"International",
        email:''
      },
      isErrorMsg: false,
      successDialog: false
    }
  },
  mounted() {
    this.listProductRegister()
  },
  methods:{
    //下载账户版软件
    downloadAccountSoftward(){
      if(this.productRegister.accountUrl){
        window.open(this.productRegister.accountUrl, "_blank")
      }else{
        this.$message.error('Download address not found')
      } 
    },
    //验证序列码是否可用
    verifyNo(){
      this.isErrorMsg = false
      if(!this.productRegister.productNo){
        this.$message.error("Product ID is required");
        return;
      }
      getProductByNoHttp(this.productRegister.productNo).then(({data})=>{        
          if(data != null && data.activationUserId == 0){
            this.productRegister.productId = data.id
            this.productRegister.productTypeId = data.productTypeId
            this.productRegister.activationCode = data.activationCode
            this.addProductRegister()
          }else{
            this.isErrorMsg = true
          }  
      })
    },
    //产品激活
    addProductRegister(){
        if(!this.productRegister.productId || !this.productRegister.productTypeId || !this.productRegister.activationCode){
          this.isErrorMsg = true
          return false
        }
        this.productRegister.memberId = this.gMemberId
        this.productRegister.email = this.gEmail
        addProductRegisterHttp(this.productRegister).then(({data})=>{
          this.productRegister.accountUrl = data.accountEnUrl
          this.successDialog = true
          this.listProductRegister()
        })
    },
    //获取产品注册列表
    listProductRegister(){
      listProductRegisterHttp(this.gMemberId).then(({data})=>{
          this.productRegisterList = data
      })
    },
  }  
  
}
</script>

<style lang="scss">
.ProductRegistration {
	padding-top: 0;
	color: #282828;
	line-height: 1;
  .success-dialog{
    .el-dialog__header{
      background-color: #FFF;
    }
    .success-title{
      color: #C81432;
      font-size: 1.04vw;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
    }
    .success-content{
      margin-top: 36px;
      font-size: 0.83vw;
      font-weight: normal;
      line-height: 28px;
      text-align: center;
      color: #282828;
    }
    .el-dialog__footer {
      margin-top: 30px;
      text-align: center;
    }  
  }
  .el-input__inner{
    margin-top: 5px;
    width: 46.2vw;
    height: 48px;
    border-radius: 10px;
    opacity: 1;
    box-sizing: border-box;
    border: 1px solid #AAAAAA;
    background-color: #FFF;
  }
    
  .red{
    color: #C81432;
    text-decoration:underline;
  }
  .error_msg{
     color: #C81432;
     font-weight: bold;
     line-height: 20px;
     font-size: 0.83vw;
     margin-top: 12px;
  }
  .card {
    border-radius: 10px;
    background: #F9F9F9;
  }

  .user-wrap {
    height: 126px;
    padding-left: 36px;
    padding-top: 34px;
    margin-bottom: 24px;
  }
  .ProductRegistration-table {
    /* 其他样式 */
    /* 设置外边框为圆角 */
    .el-table__body,
    .el-table__header-wrapper {
      border-radius: 10px; /* 根据需要调整圆角大小 */
    }
    .el-table__body .cell {
        font-size: 0.72vw;
        color: #666666;
    }
    .el-table__header .cell {
        font-size: 0.93vw;
        color: #282828;
    }
  }
  .el-button {
      width: 12.5vw;
      height: 48px;
      border-radius: 10px;
      opacity: 1;
			background: #b90025;
			margin-top: 5px;
      margin-left: -20px;
			font-size: 0.83vw;
			font-weight: normal;
			span {
				color: #ffffff;
			}
		}  

}
</style>
